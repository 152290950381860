/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800&family=Open+Sans:wght@400;600;700;800&display=swap');
/* Adobe Fonts */
@import url("https://use.typekit.net/bqn0hzj.css");
/* Bootstrap */
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css');

html,body {
  margin:0;
  padding:0;
  height: 100%;
  width:100%;
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
